import { useSearchParams } from "react-router-dom";
import useUserRoleFlags from "hooks/auth/useUserRoleFlags";
import useSelectedCropYearId, {
  CROP_YEAR_ID_QUERY_STRING_KEY,
} from "hooks/useSelectedCropYearId";
import dashboardPaths from "routes/dashboard/dashboardPaths";
import usersPaths from "routes/user/list/usersPaths";
import manurePaths from "routes/manure/manurePaths";
import useSelectedManureYearId, {
  MANURE_YEAR_ID_QUERY_STRING_KEY,
} from "./useSelectedManureYearId";
import useSelectedFarmId, {
  FARM_ID_QUERY_STRING_KEY,
} from "./useSelectedFarmId";
import haylagePaths from "routes/haylage";
import useSelectedHaylageYearId, {
  HAYLAGE_YEAR_ID_QUERY_STRING_KEY,
} from "./useSelectedHaylageYearId";

export const useNavigationRoute = (): {
  usersRoute: string;
  dashboardRoute: string;
  manureRoute: string;
  haylageRoute: string;
} => {
  const { isEmployee } = useUserRoleFlags();
  const [searchParams] = useSearchParams();
  const [farmId] = useSelectedFarmId();
  const [cropYearId] = useSelectedCropYearId();
  const [manureYearId] = useSelectedManureYearId();
  const [haylageYearId] = useSelectedHaylageYearId();

  if (isEmployee) {
    return {
      usersRoute: `/${usersPaths.basePath}`,
      dashboardRoute: cropYearId
        ? `/${dashboardPaths.basePath}?${CROP_YEAR_ID_QUERY_STRING_KEY}=${cropYearId}`
        : `/${dashboardPaths.basePath}`,
      manureRoute: `/${manurePaths.basePath}/${manurePaths.children.allFarms}`,
      haylageRoute: `/${haylagePaths.basePath}/${haylagePaths.children.allFarms}`,
    };
  } else {
    return {
      dashboardRoute: cropYearId
        ? `/${dashboardPaths.basePath}?${FARM_ID_QUERY_STRING_KEY}=${farmId}&${CROP_YEAR_ID_QUERY_STRING_KEY}=${cropYearId}`
        : `/${dashboardPaths.basePath}?${FARM_ID_QUERY_STRING_KEY}=${farmId}`,
      usersRoute: `/${usersPaths.basePath}?${searchParams}`,
      manureRoute: `/${manurePaths.basePath}/${manurePaths.children.farmDocuments}?${FARM_ID_QUERY_STRING_KEY}=${farmId}&${MANURE_YEAR_ID_QUERY_STRING_KEY}=${manureYearId}`,
      haylageRoute: `/${haylagePaths.basePath}/${haylagePaths.children.farmDocuments}?${FARM_ID_QUERY_STRING_KEY}=${farmId}&${HAYLAGE_YEAR_ID_QUERY_STRING_KEY}=${haylageYearId}`,
    };
  }
};
